import * as React from 'react';
import { graphql } from 'gatsby';
import { LABEL, CART } from '@core/constants';
import { useEntities } from '@core/hooks';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import EntitiesList from '@components/EntitiesList';
import ProductCategoryFilter from '@components/store/ProductCategoryFilter';
import ProductGrid from '@components/store/ProductGrid';
import ProductItem from '@components/store/ProductItem';
import { PostgresData, Product, ProductCategory } from '@interface/gatsby';
import { PageProps } from '@interface/common';


type DataType = PostgresData<Record<'category', ProductCategory>>;

export default function ProductCategoryTemplate({ data, categories }: PageProps<DataType>) {
  const { category } = data.postgres;
  const products = useEntities<Product>(category?.products || []);

  return (
    <Main>
      <ProductCategoryFilter categories={categories!}/>
      <ProductGrid>
        <EntitiesList<Product>
          entities={products}
          render={({ item }) => (
            <ProductItem
              {...item}
              type={CART.ITEM.PRODUCTS}
              title={item.title}
              description={category.description}
            />
          )}
        />
      </ProductGrid>
    </Main>
  );
}

export function Head({ data }: PageProps<DataType>) {
  const { category } = data.postgres;

  return (
    <BaseHead
      title={[category.title, LABEL.PRODUCTS]}
      description={category.meta.description}
      keywords={category.meta.keywords}
    />
  );
}

export const productsByCategoryQuery = graphql`
    query ProductCategoryBySlug($slug: String!) {
        postgres {
            category: productCategoryBySlug(slug: $slug) {
                id
                title
                description
                slug
                meta
                products: productsByCategoryIdList(orderBy: CREATED_AT_DESC) {
                    id
                    title
                    slug
                    url(categorySlug: $slug)
                    price
                    cover {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED,
                                breakpoints: [750],
                                placeholder: BLURRED,
                                width: 750,
                                quality: 75
                            )
                        }
                    }
                }
            }
        }
    }
`;
